<template>
    <div class="timig-hall-compare-table__box box">
        <table class="timig-hall-compare-table__table timig-hall-compare-table__table_striped timig-hall-compare-table__table_hover">
            <caption class="timig-hall-compare-table__caption">
                <template v-if="reportRow.link">
                    <router-link class="link link_red"
                        :to="reportRow.link.to"
                        :title="reportRow.link.title"
                    >
                        {{reportRow.caption}}
                    </router-link>
                </template>
                <template v-else>{{reportRow.caption}}</template>
            </caption>
            <col class="timig-hall-compare-table__column"
                v-for="column in columns"
                :key="column.id"
                :class="`timig-hall-compare-table__column_${column.id}`"
            >
            <thead>
                <tr class="timig-hall-compare-table__row timig-hall-compare-table__row_head">
                    <th class="timig-hall-compare-table__cell timig-hall-compare-table__cell_head" scope="col"
                        v-for="column in columns"
                        :key="column.id"
                    >
                        <div class="timig-hall-compare-table__cell-content timig-hall-compare-table__cell-content_columns"
                            :class="`timig-hall-compare-table__cell-content_${column.alignment}`"
                        >
                            <span class="timig-hall-compare-table__value">{{column.title}}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="timig-hall-compare-table__row timig-hall-compare-table__row_body">
                    <td class="timig-hall-compare-table__cell"
                        v-for="column in columns"
                        :key="column.id"
                        :class="{ 'timig-hall-compare-table__cell_title': column.id === 'title' }"
                    >
                        <div class="timig-hall-compare-table__cell-content timig-hall-compare-table__cell-content_rows"
                            :class="`timig-hall-compare-table__cell-content_${column.alignment}`"
                        >
                            <span v-if="!reportRow.hasOwnProperty(column.id) || reportRow[column.id] === null"
                                class="timig-hall-compare-table__value"
                            >
                                нет данных
                            </span>
                            <span v-else class="timig-hall-compare-table__value">
                                {{format(column, reportRow)}}
                            </span>
                            <difference class="timig-hall-compare-table__value"
                                v-if="column.hasOwnProperty('difference') &&
                                    reportRow.hasOwnProperty(column.id) &&
                                    (typeof reportRow[column.id]) === 'number' &&
                                    compareRow.hasOwnProperty(column.id) &&
                                    (typeof compareRow[column.id]) === 'number' &&
                                    compareRow[column.id] !== 0"
                                :primaryValue="reportRow[column.id]"
                                :compareValue="compareRow[column.id]"
                                :good="column.difference.good"
                                mode="percentage"
                                filter="percentFloatFormat"
                            />
                        </div>
                    </td>
                </tr>
                <tr class="timig-hall-compare-table__row timig-hall-compare-table__row_body">
                    <td class="timig-hall-compare-table__cell"
                        v-for="column in columns"
                        :key="column.id"
                        :class="{ 'timig-hall-compare-table__cell_title': column.id === 'title' }"
                    >
                        <div class="timig-hall-compare-table__cell-content timig-hall-compare-table__cell-content_rows"
                            :class="`timig-hall-compare-table__cell-content_${column.alignment}`"
                        >
                            <span class="timig-hall-compare-table__value">
                                <template v-if="!compareRow.hasOwnProperty(column.id) || compareRow[column.id] === null">нет данных</template>
                                <template v-else>{{format(column, compareRow)}}</template>
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import Difference from "@/components/Difference";

    export default {
        name: "TimingHallCompareTable",
        components: {
            Difference,
        },
        props: {
            columns: {
                type: Array,
                required: true
            },
            reportRow: {
                type: Object,
                required: true
            },
            compareRow: {
                type: Object,
                required: true
            }
        },
        methods: {
            format(column, row) {
                if (!this.$options.filters[column.filter]) {
                    return row[column.id];
                }

                return this.$options.filters[column.filter](row[column.id]);
            }
        },
    };
</script>

<style lang="scss">
    .timig-hall-compare-table__box {
        padding: 16px;
        overflow-x: auto;
    }
    .timig-hall-compare-table__table {
        width: 100%;
    }
    .timig-hall-compare-table__caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .timig-hall-compare-table__row {
        &_body {
            .timig-hall-compare-table__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .timig-hall-compare-table__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .timig-hall-compare-table__cell {
        font-size: 20px;
        padding: 12px 8px;

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_tar {
            text-align: right;
        }
    }
    .timig-hall-compare-table__cell-content {
        display: grid;
        grid-gap: 5px;

        &_columns {
            grid-auto-flow: column;
            align-items: center;

            &.timig-hall-compare-table__cell-content_left {
                justify-content: start;
            }

            &.timig-hall-compare-table__cell-content_right {
                justify-content: end;
            }
        }

        &_rows {
            grid-auto-flow: row;

            &.timig-hall-compare-table__cell-content_left {
                justify-items: start;
            }

            &.timig-hall-compare-table__cell-content_right {
                justify-items: end;
            }
        }
    }
    .timig-hall-compare-table__value {
        &_icon {
            width: 16px;
            height: 16px;
        }
    }
</style>
