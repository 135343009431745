<template>
    <tr class="timing-hall-base-table__row timing-hall-base-table__row_body">
        <td class="timing-hall-base-table__cell"
            v-for="column in columns"
            :key="column.id"
            :class="{ 'timing-hall-base-table__cell_title': column.id === 'title' }"
        >
            <div class="timing-hall-base-table__cell-content timing-hall-base-table__cell-content_rows"
                :class="`timing-hall-base-table__cell-content_${column.alignment}`"
            >
                <span v-if="!row.hasOwnProperty(column.id) || row[column.id] === null"
                    class="timing-hall-base-table__value"
                >
                    нет данных
                </span>
                <router-link v-else-if="column.id === 'title' && row.link"
                    class="timing-hall-base-table__value link link_red"
                    :to="row.link.to"
                    :title="row.link.title"
                >
                    {{row.title}}
                </router-link>
                <template v-else>
                    <span class="timing-hall-base-table__value">{{format(column, row)}}</span>
                    <difference class="timing-hall-base-table__value"
                        v-if="column.hasOwnProperty('difference') &&
                            row.hasOwnProperty(column.id) &&
                            (typeof row[column.id]) === 'number' &&
                            compareRow &&
                            compareRow.hasOwnProperty(column.id) &&
                            (typeof compareRow[column.id]) === 'number' &&
                            compareRow[column.id] !== 0"
                        :primaryValue="row[column.id]"
                        :compareValue="compareRow[column.id]"
                        :good="column.difference.good"
                        mode="percentage"
                        filter="percentFloatFormat"
                    />
                </template>
            </div>
        </td>
    </tr>
</template>

<script>
    import Difference from "@/components/Difference";

    export default {
        name: "TimingHallBaseTableBodyRow",
        components: {
            Difference,
        },
        props: {
            columns: {
                type: Array,
                required: true
            },
            row: {
                type: Object,
                required: true
            },
            compareRow: {
                type: Object,
            },
        },
        methods: {
            format(column, row) {
                if (!this.$options.filters[column.filter]) {
                    return row[column.id];
                }

                return this.$options.filters[column.filter](row[column.id]);
            },
        }
    };
</script>
