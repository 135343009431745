<template>
    <section class="timing-hall-settings">
        <h2 class="timing-hall-settings__heading sr-only">Настройки отчета "Время. Зал"</h2>
        <form class="timing-hall-settings__form" @submit.prevent>
            <fieldset class="timing-hall-settings__fieldset timing-hall-settings__fieldset_dateranges">
                <daterange-picker class="timing-hall-settings__daterange"
                    v-model="reportDaterangeModel"
                    name="reportDaterange"
                >
                    Период отчета:
                </daterange-picker>
                <daterange-picker class="timing-hall-settings__daterange"
                    v-if="modeId === 'compareOtherPeriod'"
                    v-model="compareDaterangeModel"
                    name="compareOtherPeriod"
                >
                    Период сравнения:
                </daterange-picker>
            </fieldset>
            <fieldset class="timing-hall-settings__fieldset timing-hall-settings__fieldset_selects">
                <select class="timing-hall-settings__select"
                    v-if="showModesSelect"
                    v-model="modeModel"
                >
                    <option class="timing-hall-settings__option"
                        v-for="mode in modes"
                        :key="mode.id"
                        :value="mode.id"
                    >
                        {{mode.title}}
                    </option>
                </select>
                <restaurants-filter v-if="showRestaurantsFilter" />
            </fieldset>
            <fieldset class="timing-hall-settings__fieldset timing-hall-settings__fieldset_checkboxes">
                <checkbox class="timing-hall-settings__checkbox"
                    v-model="groupKitchenDepartmentsModel"
                >
                    Группировать цеха кухни
                </checkbox>
            </fieldset>
        </form>
    </section>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import DaterangePicker from "@/components/DaterangePicker";
    import RestaurantsFilter from "@/components/RestaurantsFilter";
    import Checkbox from "@/components/Checkbox";

    export default {
        name: "TimingHallSettings",
        props: {
            showModesSelect: {
                type: Boolean,
                default: true,
            },
            showRestaurantsFilter: {
                type: Boolean,
                default: true,
            }
        },
        components: {
            DaterangePicker,
            RestaurantsFilter,
            Checkbox,
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                compareDaterange: state => state.timingHall.compareDaterange,
                modes: state => state.timingHall.modes,
                modeId: state => state.timingHall.modeId,
                groupKitchenDepartments: state => state.timingHall.groupKitchenDepartments,
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setReportDaterange(daterange);
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(value) {
                    this.setTimingHallParameter({ parameter: "compareDaterange", value });
                }
            },
            modeModel: {
                get() {
                    return this.modeId;
                },
                set(value) {
                    this.setTimingHallParameter({ parameter: "modeId", value });
                }
            },
            groupKitchenDepartmentsModel: {
                get() {
                    return this.groupKitchenDepartments;
                },
                set(value) {
                    this.setTimingHallParameter({ parameter: "groupKitchenDepartments", value });
                }
            },
        },
        methods: {
            ...mapMutations([
                "setReportDaterange",
                "setTimingHallParameter",
            ]),
        }
    };
</script>

<style lang="scss">
    .timing-hall-settings__form {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;
    }
    .timing-hall-settings__fieldset {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .timing-hall-settings__select {
        min-width: 240px;
        height: 40px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;
    }
</style>
